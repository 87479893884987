import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isLTR } from '@presentation/shared/utils';
import { finalize, Subscription } from 'rxjs';
import {
  DynamicIncentiveProgramModel,
  HistoryDynamicIncentiveProgramModel,
} from 'src/app/core/domain/dynamic-incentive-program.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetDynamicIncentiveProgramHistoryUseCase } from 'src/app/core/usecases/dynamic-incentive-program/get-dynamic-incentive-program-history.usecase';
import { GetDynamicIncentiveProgramUseCase } from 'src/app/core/usecases/dynamic-incentive-program/get-dynamic-incentive-program.usecase';
import { LoaderComponent } from 'src/app/presentation/shared/components/loader/loader.component';
import { CurrencyTranslatePipe } from 'src/app/presentation/shared/pipes/currency-translate.pipe';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';
import { CurrencyShortNamePipe } from '../../../shared/pipes/currency-short-name.pipe';
import { DynamicIncentiveProgramComponent } from '../dynamic-incentive-program/dynamic-incentive-program.component';

@Component({
  selector: 'app-multiple-dynamic-incentive-program',
  templateUrl: './multiple-dynamic-incentive-program.component.html',
  styleUrls: ['./multiple-dynamic-incentive-program.component.scss'],
  standalone: true,
  imports: [
    DynamicIncentiveProgramComponent,
    NgFor,
    TranslateModule,
    NgClass,
    NgIf,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    LoaderComponent,
  ],
})
export class MultipleDynamicIncentiveProgramComponent implements OnInit, OnDestroy {
  @Input() shouldOnlyDisplayIncentiveWidget = false;

  public dynamicIncentiveData?: DynamicIncentiveProgramModel[];

  public dynamicIncentiveHistoryData: HistoryDynamicIncentiveProgramModel[];

  public isDynamicIncentiveHistoryData = false;

  public currency: string;

  public assetsPath = 'assets/img/dynamic-incentive/';

  public isLTR = isLTR();

  public isDynamicIncentiveData = false;

  public isLoading = false;

  public numberOfPrograms: number;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _getDynamicIncentiveProgramUseCase: GetDynamicIncentiveProgramUseCase,
    private _siteTranslateService: SiteTranslateService,
    private _getDynamicIncentiveProgramHistoryUseCase: GetDynamicIncentiveProgramHistoryUseCase,
    private _multitenancyService: MultitenancyService,
    private _translateService: TranslateService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {}

  ngOnInit(): void {
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: () => {
        this.isLTR = isLTR();
      },
    });

    this.getDynamicIncentiveProgram();
    this.getDynamicIncentiveProgramHistory();
    this._logMixpanelEventUseCase.execute({
      eventName: 'dynamic_incentive_page_loaded',
    });
  }

  getDynamicIncentiveProgram(): void {
    this.isLoading = true;
    this._getDynamicIncentiveProgramUseCase
      .execute()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (dynamicIncentiveData) => {
          this.isDynamicIncentiveData = true;
          this.dynamicIncentiveData = dynamicIncentiveData;
          this.numberOfPrograms = dynamicIncentiveData.length;
        },
        error: () => {
          this.dynamicIncentiveData = undefined;
        },
      });
  }

  getDynamicIncentiveProgramHistory(): void {
    this._getDynamicIncentiveProgramHistoryUseCase.execute().subscribe({
      next: (historyData) => {
        this.isDynamicIncentiveHistoryData = true;
        this.dynamicIncentiveHistoryData = historyData.map((history) => {
          return {
            ...history,
            name: this.isLTR ? history.challengeName.en : history.challengeName.ar,
            startDate: this.calculateCurrentFormattedDate(history.startedAt.toDateString()),
            endDate: this.calculateCurrentFormattedDate(history.endedAt.toDateString()),
            profitStatus: history.rewardsStatus
              ? `DYNAMIC_INCENTIVE.HISTORY.${history.rewardsStatus}`
              : '-',
            feedback: history.challengeFeedbackRate
              ? `DYNAMIC_INCENTIVE.HISTORY.${history.challengeFeedbackRate}`
              : '-',
          };
        });
      },
      error: () => {},
    });
  }

  calculateCurrentFormattedDate(date: string): string {
    const selectedLanguage = this._siteTranslateService.getCurrentLanguage();
    const { year, month, day } = DateWrapperUtility.getFormattedDateParts(
      date,
      { day: 'D', month: 'MMM', year: 'YYYY' },
      selectedLanguage,
    );
    return `${day} ${month} ${year}`;
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
